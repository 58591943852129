import * as bootstrap from "bootstrap";

window.bootstrap = bootstrap;

// import then needed Font Awesome functionality
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleUp,
  faArrowsV,
  faBahtSign,
  faBars,
  faBarsProgress,
  faCalculator,
  faCalendar,
  faCar,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleInfo,
  faCircleNotch,
  faClipboardList,
  faClock,
  faComments,
  faEdit,
  faEraser,
  faEye,
  faFileText,
  faGear,
  faHotel,
  faKey,
  faLifeRing,
  faMoneyBill,
  faMoon,
  faPlus,
  faRocket,
  faSave,
  faSearch,
  faSignOutAlt,
  faStore,
  faSun,
  faTable,
  faTachometerAlt,
  faTicket,
  faTimes,
  faTrash,
  faUser,
  faUsers,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { faDev } from "@fortawesome/free-brands-svg-icons";

// add the imported icons to the library
library.add(
  faBars,
  faSun,
  faMoon,
  faUser,
  faTachometerAlt,
  faCalculator,
  faTable,
  faFileText,
  faStore,
  faKey,
  faSignOutAlt,
  faUsers,
  faCar,
  faLifeRing,
  faRocket,
  faHotel,
  faMoneyBill,
  faBahtSign,
  faCalendar,
  faAngleUp,
  faWrench,
  faCircleInfo,
  faGear,
  faDev,
  faBarsProgress,
  faClipboardList,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faSave,
  faTimes,
  faEye,
  faComments,
  faClock,
  faCheck,
  faSearch,
  faEraser,
  faEdit,
  faArrowsV,
  faTrash,
  faCircleNotch,
  faTicket
);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

import * as tempusDominus from "@eonasdan/tempus-dominus";

window.tempusDominus = tempusDominus;

(function () {
  "use strict"; // Start of use strict

  var sidebar = document.querySelector(".sidebar");
  var sidebarToggles = document.querySelectorAll(
    "#sidebarToggle, #sidebarToggleTop"
  );

  if (sidebar) {
    var collapseEl = sidebar.querySelector(".collapse");
    var collapseElementList = [].slice.call(
      document.querySelectorAll(".sidebar .collapse")
    );

    var sidebarCollapseList = collapseElementList.map(function (collapseEl) {
      return new bootstrap.Collapse(collapseEl, {
        toggle: false,
      });
    });

    for (var toggle of sidebarToggles) {
      // Toggle the side navigation
      toggle.addEventListener("click", function (e) {
        document.body.classList.toggle("sidebar-toggled");
        sidebar.classList.toggle("toggled");

        if (sidebar.classList.contains("toggled")) {
          for (var bsCollapse of sidebarCollapseList) {
            bsCollapse.hide();
          }
        }
      });
    }

    // Close any open menu accordions when window is resized below 768px
    window.addEventListener("resize", function () {
      var vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );

      if (vw < 768) {
        for (var bsCollapse of sidebarCollapseList) {
          bsCollapse.hide();
        }
      }
    });
  }

  // Prevent the content wrapper from scrolling when the fixed side navigation hovered over

  var fixedNavigation = document.querySelector("body.fixed-nav .sidebar");

  if (fixedNavigation) {
    fixedNavigation.on("mousewheel DOMMouseScroll wheel", function (e) {
      var vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );

      if (vw > 768) {
        var e0 = e.originalEvent,
          delta = e0.wheelDelta || -e0.detail;
        this.scrollTop += (delta < 0 ? 1 : -1) * 30;
        e.preventDefault();
      }
    });
  }

  var scrollToTop = document.querySelector(".scroll-to-top");

  if (scrollToTop) {
    // Scroll to top button appear
    window.onscroll = function () {
      //check if user is scrolling up
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        scrollToTop.classList.add("show");
      } else {
        scrollToTop.classList.remove("show");
      }
    };
  }

  var btnSwitch = document.getElementById("btnSwitch");

  if (btnSwitch) {
    btnSwitch.addEventListener("click", () => {
      if (document.body.classList.contains("dark")) {
        sessionStorage.setItem("theme", "light");
        document.body.classList.remove("dark");
        btnSwitch.innerHTML = '<i class="fa-solid fa-moon fa-fw fa-sm"></i>';
      } else {
        sessionStorage.setItem("theme", "dark");
        document.body.classList.add("dark");
        btnSwitch.innerHTML = '<i class="fa-solid fa-sun fa-fw fa-sm"></i>';
      }
    });
  }

  var theme = sessionStorage.getItem("theme");

  if (theme == "dark") {
    document.body.classList.add("dark");
    btnSwitch.innerHTML = '<i class="fa-solid fa-sun fa-fw fa-sm"></i>';
  } else {
    document.body.classList.remove("dark");
  }

  const ellipsis = [...document.querySelectorAll(".ellipsis")];

  ellipsis.forEach((input) => {
    let row = input.getAttribute("data-row");

    var options = {
      row: row ? row : 1,
      char: "...",
    };

    let $this = input;
    let text = $this.innerText;

    let origHeight = $this.offsetHeight;

    $this.innerText = "a";

    let style = window.getComputedStyle($this);
    let lineHeight = parseFloat(style.getPropertyValue("line-height"), 10);
    let rowHeight = $this.offsetHeight;
    let gapHeight = lineHeight > rowHeight ? lineHeight - rowHeight : 0;
    let targetHeight = gapHeight * (options.row - 1) + rowHeight * options.row;

    if (origHeight <= targetHeight) {
      $this.innerText = text;
      return;
    }

    let start = 1,
      length = 0;
    let end = text.length;

    while (start < end) {
      length = Math.ceil((start + end) / 2);
      $this.innerText = text.slice(0, length) + options.char;

      if ($this.offsetHeight <= targetHeight) {
        start = length;
      } else {
        end = length - 1;
      }
    }

    text = $this.innerText = text.slice(0, start) + options.char;
  });
})(); // End of use strict
